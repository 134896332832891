#create-component {
    .form-title {
        font-size: 36px;
        font-weight: bold;
    }

    .colors {
        .d-flex {
            display: flex;

            label {
                margin-right: .65rem;
            }

            .black,
            .blue {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                transition: all 200ms ease;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                }
            }

            .black {
                background-color: black;
                margin-right: .65rem;
            }

            .blue {
                background-color: #007DFB;
            }
        }
    }
}

.input-group-text {
    background-color: transparent;
    border-color: transparent;
    font-weight: bold;
    border-bottom: 1px solid #7c7c7cc2;
    border-radius: 0 !important;
}

input[type="text"],
select.form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid #7c7c7cc2;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

input[type="text"]:focus,
select.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border-color: #7C55F8;
}