.btn-preview-style {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem 1.25rem .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    img {
        margin-right: 1rem;
    }
}