html,
body {
  min-height: 100%;
}

body {
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  background-color: #F3F4F5;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.box {
  height: 484px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #7C55F8;
}

p {
  line-height: 1.5;
}

.form-label-sm {
  font-size: 12px;
  font-weight: 500;
}

.has-radius {
  border-radius: 1rem;
}

@media (max-width: 992px) {
  .content-area {
    margin-top: 0 !important;
  }

  .col-first-mob {
    margin-top: 2rem;
    padding: 3rem !important;
  }

  .preview-content {
    padding-left: 0;
  }
}